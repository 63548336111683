<template>
  <div class="d-flex flex-column">
    <div class="server-info">
      <v-text-field
        class="mr-4 adr"
        :label="$t('t.ServerAddress')"
        v-model="computedAddress"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <number-field
        class="port mr-4"
        :number.sync="computedPort"
        :label="$t('t.Port')"
      />
      <v-select
        class="encryption"
        :items="encryptionItems"
        item-text="text"
        item-value="id"
        :label="$t('t.Encryption')"
        v-model="computedEncryption"
      />
      <number-field
        :number.sync="computedMessageMaxMegabytes"
        :label="$t('t.MessageMaxMegabytes')"
        class="max-megabytes"
        :rules="messageMaxMegabytesRules"
      />
    </div>
    <div>
      <v-switch
        dense
        :label="$t('t.RequiresAuthentification')"
        v-model="computedRequiresAuthentification"
      />
      <div
        v-if="computedRequiresAuthentification"
        class="d-flex flex-column mb-4"
      >
        <v-text-field
          class="username mt-4"
          :label="$t('t.Username')"
          v-model="computedUsername"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <v-text-field
          class="password"
          :label="$t('t.Password')"
          v-model="computedPassword"
          :type="'password'"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
      </div>
      <div class="d-flex align-center">
        <v-text-field
          type="email"
          class="email mr-4"
          :prepend-icon="$icon('i.Email')"
          :label="$t('t.EmailForTest')"
          v-model="computedEmail"
          :clearable="true"
        />
        <v-btn
          class="connect-btn"
          rounded
          justify-center
          color="primary"
          @click="testConnect()"
          v-ripple
          :loading="testConnectWorking"
          :disabled="!computedEmail"
        >
          <span>{{$t('t.TestConnection')}}</span>
        </v-btn>
      </div>
    </div>

    <v-bottom-sheet
      v-model="showError"
      persistent
      v-if="error"
      :hide-overlay="false"
    >
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          color="error"
          @click="closeError()"
        >
          {{$t('t.Ok')}}
        </v-btn>
        <div class="py-3 h6">
          {{error.title}}
        </div>
        <div class="py-3 body-2">
          {{error.message}}
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>

export default {
  components: {
    NumberField: () => import('@/components/number-field')
  },
  data () {
    return {
      encryptionItems: [
        { id: 'StartTLS_1_2', text: 'StartTLS 1.2' },
        { id: 'StartTLS_1_1', text: 'StartTLS 1.1' },
        { id: 'StartTLS_1_0', text: 'StartTLS 1.0' },
        { id: 'TLS_1_2', text: 'TLS 1.2' },
        { id: 'TLS_1_1', text: 'TLS 1.1' },
        { id: 'TLS_1_0', text: 'TLS 1.0' },
        { id: 'Unencrypted', text: this.$t('t.Unencrypted') }
      ],
      error: null,
      showError: false,
      testConnectWorking: false,
      dataMessageMaxMegabytes: 20,
      dataEmail: null,
      dataAddress: null,
      dataEncryptKey: null,
      dataProviderId: null,
      dataCapabilities: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataEncryption: null,
      dataPassword: null,
      dataPort: null,
      dataRequiresAuthentification: false,
      dataUsername: null,
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      passwordChanged: false
    }
  },
  computed: {
    messageMaxMegabytesRules () {
      return [
        v => (v >= 1 && v <= this.dataMessageMaxMegabytes) || this.$t('t.InvalidValueRange', { min: 1, max: this.dataMessageMaxMegabytes })
      ]
    },
    computedEmail: {
      get () {
        return this.dataEmail
      },
      set (v) {
        this.dataEmail = v
        this.dataDocumentEmit()
      }
    },
    computedIsUnencrypted () {
      return this.computedEncryption === 'Unencrypted'
    },
    ruleRequiredMax200 () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 200
      ]
    },
    authFieldsRules () {
      return [
        v => (this.computedRequiresAuthentification && !!v) || this.$t('t.IsRequired'),
        v => (this.computedRequiresAuthentification && (v ?? '')).length <= 200
      ]
    },
    computedMessageMaxMegabytes: {
      get () {
        return this.dataMessageMaxMegabytes
      },
      set (v) {
        this.dataMessageMaxMegabytes = v
        this.dataDocumentEmit()
      }
    },
    computedAddress: {
      get () {
        return this.dataAddress
      },
      set (v) {
        this.dataAddress = v
        this.dataDocumentEmit()
      }
    },
    computedPort: {
      get () {
        return this.dataPort
      },
      set (v) {
        this.dataPort = v
        this.dataDocumentEmit()
      }
    },
    computedUsername: {
      get () {
        return this.dataUsername
      },
      set (v) {
        this.dataUsername = v
        this.dataDocumentEmit()
      }
    },
    computedPassword: {
      get () {
        return this.dataPassword
      },
      set (v) {
        this.passwordChanged = this.dataPassword !== v
        this.dataPassword = v
        this.dataDocumentEmit()
      }
    },
    computedEncryption: {
      get () {
        return this.dataEncryption || 'StartTLS_1_2'
      },
      set (v) {
        this.dataEncryption = v

        this.dataDocumentEmit()
      }
    },
    computedRequiresAuthentification: {
      get () {
        return this.dataRequiresAuthentification
      },
      set (v) {
        this.dataRequiresAuthentification = v

        this.dataUsername = null
        this.dataPassword = null
        this.passwordChanged = true

        this.dataDocumentEmit()
      }
    },
    canTest () {
      if (!this.computedAddress || !this.computedPort || !this.computedUsername) { return false }
      if (this.computedRequiresAuthentification && !this.computedPassword) { return false }

      return this.computedPort?.toString().length > 0 &&
        this.computedAddress?.length > 0
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        ty: 'smtp',
        v: {
          address: this.dataAddress,
          encryptKey: this.dataEncryptKey,
          encryption: this.dataEncryption,
          messageMaxMegabytes: this.dataMessageMaxMegabytes,
          password: this.dataPassword,
          passwordChanged: this.passwordChanged,
          port: this.dataPort,
          providerId: this.dataProviderId,
          requiresAuthentification: this.dataRequiresAuthentification,
          username: this.dataUsername
        }
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    closeError () {
      this.showError = false
      this.error = null
    },
    async testConnect () {
      this.testConnectWorking = true
      const s = this.document.v
      s.from = this.dataEmail

      await this.$http().post('/core/providers/smtp/test', s)
        .then(result => {
          const r = result.data
          if (r.hasError) {
            this.error = {
              title: this.$t('t.Snackbar.TestConnectionError'),
              message: r.message
            }
            this.showError = true
          } else {
            this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SuccessConfirmation', { title: this.$t('t.TestConnection') }))
          }
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
        .finally(e => {
          this.testConnectWorking = false
        })
    }
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    document: Object
  },
  watch: {
    document: {
      immediate: true,
      handler (d) {
        const v = d?.v
        this.dataAddress = v?.address
        this.dataMessageMaxMegabytes = v?.messageMaxMegabytes || 20
        this.dataEncryptKey = v?.encryptKey
        this.dataProviderId = v?.providerId
        this.dataCapabilities = v?.capabilities
        this.dataEncryption = v?.encryption || 'StartTLS_1_2'
        this.dataPassword = v?.password
        this.dataPort = v?.port
        this.dataRequiresAuthentification = v?.requiresAuthentification
        this.dataUsername = v?.username
      }
    },
    isDirty: {
      immediate: true,
      handler (v) {
        if (!v) { this.passwordChanged = false }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.server-info
  display grid
  grid-template-columns 70% 10% auto
  grid-template-areas 'adr port encrypt'

.adr
  grid-area adr

.port
  grid-area port

.encryption
  grid-area encrypt

.connect-btn
  max-width 400px

.username, .password, .email
  max-width 30%

.max-megabytes
  max-width 160px
</style>
